@media screen and (min-width: 360px){
    .loginMainDiv{
        position: relative;
        .loginContent{
            display: flex;
            flex-direction: column;
            .formsDiv{
                width: 100%;
                margin-top: 20px;
                margin-bottom: 0px;
                .title{
                    font-size: 20px;
                    line-height: 28px;
                    margin-bottom: 20px;
                }
                .text{
                    margin-bottom: 30px;
                    p{
                        line-height: 20px;
                    }
                }
            }
            .image{
                width: 220px !important;
                height: 280px !important;
                margin: 0 auto;
            }
        }
    }
    .submitBtn:disabled{
        opacity: .5;
        cursor: not-allowed;
    }
}

@media screen and (min-width: 768px){
    .loginMainDiv{
       .loginContent{
            .image{
                width: 245px !important;
                height: 320px !important;
            }
        }
     }
}

@media screen and (min-width: 992px){
    .loginMainDiv{
       .loginContent{
            flex-direction: row;
            justify-content: space-between;
            .formsDiv{
                width: 50%;
                margin-top: 100px;
                margin-bottom: 100px;
                .title{
                    font-size: 28px;
                    line-height: 34px;
                    margin-bottom: 20px;
                }
            }
            .image{
                position: absolute;
                bottom: 0;
                right: 45px;  
                width: 425px !important;
                height: 520px !important;
                margin: 0;
            }
        }
     }
}

@media screen and (min-width: 1220px){
    .loginMainDiv{
        .loginContent{
            .image{
                right: 70px;  
                width: 465px !important;
                height: 550px !important;
            }
        }
    }
}


